import React from "react";
import { validateEmail } from "../../../utils/functions";
import * as classes from "./index.module.less";

let validateField = (type, value, validateNow, required) => {
  if (!validateNow) return null;
  if (type === "email") {
    return required && validateEmail(value)
      ? null
      : "Please enter a valid email.";
  }else if(type === "textarea"){
    // check that the length of the message is greater than 15 char
    return required && value && value.length > 15 ? null : "Your message must be at least 15 characters long.";
  } else {
    return required && !!value ? null : "Required field";
  }
};

export default ({
  label,
  onChange,
  name,
  value,
  type,
  options,
  disabled,
  required,
  validateNow,
}) => {
  let error = validateField(type, value, validateNow, required);
  return (
    <div
      className={[
        classes.container,
        type === "checkbox" ? classes.checkbox : "",
      ].join(" ")}
    >
      {type !== "checkbox" ? <label htmlFor={name}>{label}</label> : null}
      {type === "textarea" ? (
        <textarea
          onChange={(e) => onChange(e.target.value, name)}
          name={name}
          value={value}
          disabled={disabled}
        ></textarea>
      ) : type === "select" ? (
        <select
          onChange={(e) => onChange(e.target.value, name)}
          disabled={disabled}
        >
          {options.map((it, key) => (
            <option key={key} value={it}>
              {it}
            </option>
          ))}
        </select>
      ) : type === "checkbox" ? (
        <p
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <input
            type="checkbox"
            name={name}
            checked={value}
            onChange={(e) => onChange(e.target.checked, name)}
            disabled={disabled}
            style={{
              width: "25px",
              paddingRight: "10px",
              paddingTop: "5px",
              margin: "12px 12px 0 0", 
            }}
          />
          <span>
            {label}
          </span>
        </p>
      ) : (
        <input
          onChange={(e) => onChange(e.target.value, name)}
          name={name}
          value={value}
          type={type === "number" ? "number" : "text"}
          disabled={disabled}
        />
      )}
      {error ? (
        <p style={{paddingBottom: "10px"}}className={classes.error}>{error}</p>
      ) : (
        <p className={classes.noerror}>correct</p>
      )}
    </div>
  );
};
