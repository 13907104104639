import React, { useEffect, useState } from "react";
import * as classes from "./index.module.less";
import Layout from "components/shared/Layout";
import Button from "components/shared/Button";
import Input from "../Input";
import { StaticImage } from "gatsby-plugin-image";
import { handleFormSubmission, TrackAnalyticsEvent } from "src/utils/functions";

export default () => {
  let [values, setValues] = useState({
    newsletter_signup: true,
    company_size: "1",
  });
  let [linkedinUrl, setLinkedinUrl] = useState(null);
  let [loading, setLoading] = useState(false);
  let [sent, setSent] = useState(false);
  let [validateNow, setValidateNow] = useState(false);

  let handleChange = (value, name) => {
    // dataLayer.push({'event': 'form_submitted', "email": });
    setValidateNow(false);
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    if (typeof window === "undefined") return;
    const urlObject = new URL(window.location.href);
    let email = urlObject.searchParams.get("email");
    if (!!email) {
      handleChange(decodeURIComponent(email), "email");
    }
  }, []);

  let getIP = async () => {
    return new Promise((resolve) => {
      try {
        fetch("https://www.cloudflare.com/cdn-cgi/trace")
          .then((response) => response.text())
          .then((data) => {
            resolve(
              data?.replace(/ts/g, "")?.replace(/\n/g, "")?.split("=")[3]
            );
          });
      } catch (e) {
        resolve("");
      }
    });
  };

  let terminate = () => {
    setSent(true);
    // handleFormSubmission("contactForm");
  };

  let sendData = async (e) => {
    e.preventDefault();
    setValidateNow(true);
    if (
      // Make sure the different fields are not empty
      // The message must be at least 15 characters long
      loading ||
      !values.name ||
      !values.email ||
      !values.message ||
      !values.company_name ||
      !values.company_size ||
      !values.message ||
      values.message.length < 15
    ) {
      return false;
    }

    if (typeof window === "undefined" || typeof document === "undefined") {
      // Do nothing
    }else{
      window.dataLayer.push({'event': 'form_submitted', "email": values.email});
    }

    setLoading(true);
    let ip_address = await getIP();

    // read the navigationRecord from the local storage. the navigationRecord is a stringified array of urls
    let navigationRecord = []
    if (window.localStorage.navigation) {
      // read the local storage to get the navigationRecord. The navigationRecord is a stringified array of urls
      navigationRecord = JSON.parse(window.localStorage.navigation);
    }

    // get the last page minus 1, minus 2, minus 3 and minus 4. Consider that the last page is the current page. Also it might be the case that the user has not visited 4 pages before the current page.
    let pagesHistory = {
      last_page_minus_0: "",
      last_page_minus_1: "",
      last_page_minus_2: "",
      last_page_minus_3: "",
      last_page_minus_4: "",
    };

    // We only want to track the previous N pages
    const maxPagesToTrack = 4;
    for (let i = 1; i <= maxPagesToTrack + 1 && navigationRecord.length >= i; i++) {
      pagesHistory[`last_page_minus_${i-1}`] = navigationRecord[navigationRecord.length - i];
    }

    // check the local storage to see if there is a gclid, if there is, then we add it to the data
    let gclid = "undefined";
    if (window.localStorage.gclid && window.localStorage.gclid !== undefined) {
      gclid = window.localStorage.gclid;
    }

    let data = {
      ...values,
      form: "contactForm",
      source: window.localStorage.source,
      referer: window.localStorage.referrer,
      ip_address,
      gclid,

      // Navigation history
      first_page: window.localStorage.first_page,
      last_page: pagesHistory.last_page_minus_0,
      last_page_minus_1: pagesHistory.last_page_minus_1,
      last_page_minus_2: pagesHistory.last_page_minus_2,
      last_page_minus_3: pagesHistory.last_page_minus_3,
      last_page_minus_4: pagesHistory.last_page_minus_4,
      ctaId: window.localStorage.ctaId ? window.localStorage.ctaId : "undefined",
    };

    fetch(`${process.env.GATSBY_ENDPOINT}/website/form-endpoint/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "success") {
          setLoading(false);
          terminate();
        } else throw data;
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
        alert(
          "An error happened, please send us an email to info@appinchina.co"
        );
      });
  };

  return (
    <div className={classes.container}>
      <Layout>
        <div className={classes.content}>
          <form onSubmit={sendData}>
            <h1>Contact Us</h1>
            <p>
              Fill out our form and one of our team members will contact you
              shortly, or send us an email at{" "}
              <a
                href="mailto:info@appinchina.co"
                rel="noopener noreferrer"
                target="_blank"
              >
                info@appinchina.co
              </a>
            </p>

            <div className={classes.pair}>
              <Input
                onChange={handleChange}
                label="Name"
                value={values["name"]}
                name="name"
                disabled={loading || sent}
                required
                validateNow={validateNow}
              />
              <Input
                onChange={handleChange}
                label="Email"
                value={values["email"]}
                name="email"
                type="email"
                disabled={loading || sent}
                required
                validateNow={validateNow}
              />
            </div>
            <div className={classes.pair}>
              <Input
                onChange={handleChange}
                label="Company Name"
                value={values["company_name"]}
                name="company_name"
                disabled={loading || sent}
                required
                validateNow={validateNow}
              />
              <Input
                onChange={handleChange}
                label="Company Size"
                value={values["company_size"]}
                name="company_size"
                type="select"
                required
                options={["1", "2-10", "11-50", "51-200", "200+"]}
                disabled={loading || sent}
                validateNow={validateNow}
              />
            </div>
            <Input
              onChange={handleChange}
              label="Phone Number"
              value={values["phone"]}
              name="phone"
              type="number"
              disabled={loading || sent}
            />
            <Input
              onChange={handleChange}
              label="Message"
              value={values["message"]}
              name="message"
              type="textarea"
              required
              disabled={loading || sent}
              validateNow={validateNow}
            />
            <Input
              onChange={handleChange}
              label=" I’d like to receive a quarterly newsletter from AppInChina. (we will never spam you or share your info)."
              value={values["newsletter_signup"]}
              name="newsletter_signup"
              type="checkbox"
              disabled={loading || sent}
            />
            <div id={"submitFormButton"} className={classes.button}>
              {" "}
              {sent ? (
                <p id={"succesMessageConversionTracking"} className={classes.success}>
                  {" "}
                  Thank you! A member of our team will contact you shortly.
                </p>
              ) : (
                <Button disabled={loading}>
                  {loading ? "Loading..." : "Submit"}
                </Button>
              )}
            </div>
            {linkedinUrl ? <img src={linkedinUrl} alt="Linkedin" /> : null}
          </form>
          <StaticImage
            src="../../../images/photos/temple3.jpg"
            alt="Temple"
            placeholder="blur"
            layout="fixed"
            width={1050}
            height={900}
          />
        </div>
      </Layout>
    </div>
  );
};
