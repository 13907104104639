import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import Form from "components/get-started/Form";

export default () => (
  <>
    <Meta url="/get-started/" />
    <Nav />
    <Form />
    <Footer />
  </>
);
